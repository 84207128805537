
/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,900');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900'); */

html, body, #app, #app>div {
  height: 100vh
}

body > #root > div {
  height: 100vh;
}
  
  .menu_selected {
    border-radius: 20px 0px 0px 20px;
    background-color: #E0F3FF;
  }

  .menu_text {
    color: #000000;
    font-size: 1em;
    font-family: 'Roboto';
    margin-left: 15%;
  }

  .menu_text_selected {
    color: #2955C8;
    font-size: 1em;
    font-family: 'Roboto';
    font-weight: 500;
    margin-left: 15%;
  }

 .login-form {
   width: 50%;
    max-width: 50%;
  }
 .login-form-forgot {
    float: right;
  }
 .login-form-button {
    width: 100%;
  }

  .ui.grid>.column:not(.row), .ui.grid>.row>.column {
    padding-left: 0rem !important;
    padding-right: 0rem !important; 
  } 
 
  .ui.grid {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    margin-left: 0rem !important; 
    margin-right: 0rem !important;
  }

  .ui.grid>* {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .paddless_p {
    margin: 0px !important;
  }

  .flex_one{
    margin-right: 10px !important;
    flex-grow: 1;
  }

  .mapato_header {
    color: #696969;
    margin-left: 10px;
    font-size: 1em;
    font-family: 'Roboto';
    font-weight: 500;
    text-align: center;
  }

  .mapato_header_black {
    width: 100%;
    color: #000000;
    font-size: 1em;
    font-family: 'Roboto';
    font-weight: 600;
    text-align: center;
  }

  .ui.toggle.checkbox label ::after {
    font-size: 1em;
    font-family: 'Roboto';
    color: #F25183 !important;
  }

  .ui.toggle.checkbox label {
    font-size: 1em;
    font-family: 'Roboto';
    color: #F25183 !important;
  }

 
  .ui.toggle.checkbox input:focus~.box:before, .ui.toggle.checkbox input:focus~label:before {
    background-color: rgba(155, 151, 151, 0.815) !important;
    border: none;
   }

  .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before{
    background-color: rgba(155, 151, 151, 0.815) !important;
    border: none;
   }

  .normal_text {
    width: 100%;
    color: #000000;
    font-size: 1.1em;
    font-family: 'Roboto';
    font-weight: 500;
   }

  .light_text {
    width: 100%;
    color: rgba(0,0,0,.87);
    font-size: 1.03em;
    font-family: 'Lato';
    font-weight: 400;
   }

  .sub_title {
    width: 100%;
    color: #696969;
    font-size: 0.8em;
    font-family: 'Roboto';
    font-weight: 400;
   }

  .bottom_shadow{
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 2px 4px -4px #222;
  
   }

  .grid_margin {
      padding-left: 0rem !important;
      padding-right: 0rem !important; 
      margin-top: 1rem !important;
      margin-bottom: 0px !important;
      margin-left: 5px !important; 
      margin-right: 5px !important;
      padding-left: 0rem !important;
      padding-right: 0rem !important;
      height: 100%;
      min-height: 100%;
   }

  .main_header {
    font-size: 1.4rem;
    font-weight:600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    margin-bottom: 2px;
  
  }

  .overx {
   margin: auto;
   width: 99.2%;
   height: 100%;
  }

  .card {
    transition: 0.3s;
    border-radius:  4px;
    background-color: white;
    padding: 4px !important;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  td, th {
    border: 1px solid #dddddd;
    padding: 8px;
  }

  .table_data {
    text-align: right;
  }

  .fixed_sider {
    overflow: 'scroll';
    height: '100px';
    position: 'fixed';
    left: 0; 
  }

  .flex_center {
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  i {
    vertical-align: middle!important; /*Ant icon vertical position fix*/
  }

  .enter-payment {
    color: white;
    background-color: black;
  }

  .status_success {
 
    border-radius: 50px;
    background-color: #007BFF;
    color: white;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem;
  }
  
  .status_failed {
    padding: 3px;
    border-radius: 50px;
    background-color: #FF4D4F;
    color: white;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem;
  }

  .status_pending {
    padding: 3px;
    border-radius: 50px;
    background-color: #636060;
    color: white;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem;
  }  